<template>
 <div>
  <section class="holder">
   <div class="intro animated fadeInLeft">
    <div class="bg"></div>
    <div class="container">
     <img
      src="../assets/images/logo.png"
      class="logo animated fadeInDown"
      style="animation-delay: 250ms"
      alt="Celera Logo"
     />
     <h1 class="animated fadeInRight" style="animation-delay: 500ms">
      {{ informations.title }}
     </h1>
     <img
      src="../assets/images/survey.svg"
      class="survey animated fadeInUp"
      style="animation-delay: 500ms"
      alt="imagem de questionário de avaliação"
     />
    </div>
   </div>
   <div class="content">
    <div class="container">
     <div
      class="form animated fadeInDown"
      style="animation-delay: 750ms; animation-duration: 800ms"
     >
      <div v-show="!formSent">
       <img
        src="../assets/images/voting.svg"
        class="voting animated zoomIn"
        style="animation-delay: 1200ms"
        alt="imagem de questionário de avaliação"
       />

       <div class="rating-item animated zoomIn" style="animation-delay: 1500ms">
        <span> <b>1</b> {{ informations.firstQuestion }} </span>
        <OptionRating
         label1="Não indicaria"
         label2="Indicaria"
         :selectedValue="form.rate1"
         @setValueRate="setValueRate1"
        />
       </div>
       <div class="divider"></div>
       <div class="rating-item animated zoomIn" style="animation-delay: 1750ms">
        <span><b>2</b> {{ informations.secondQuestion }}</span>
        <OptionRating
         label1="Muito insatisfeito"
         label2="Extremamente satisfeito"
         :selectedValue="form.rate2"
         @setValueRate="setValueRate2"
        />
       </div>
       <div class="divider"></div>
       <div class="input-item animated zoomIn" style="animation-delay: 2000ms">
        <textarea
         v-model="form.message"
         :placeholder="informations.inputLabel"
        ></textarea>
       </div>

       <transition enter-active-class="animated fadeIn">
        <p v-show="warningMessage != ''" class="warning">
         {{ warningMessage }}
        </p>
       </transition>

       <div
        class="btn mt-2 desktop6 mx-auto animated zoomIn"
        style="animation-delay: 2250ms"
        @click="send"
       >
        <span v-show="!sending">Enviar Avaliação</span>
        <div v-show="sending" class="loading2 white"></div>
       </div>
      </div>

      <div v-show="formSent" class="sent-holder">
       <transition enter-active-class="animated fadeInDown">
        <h1 v-show="formSent" style="animation-delay: 250ms">
         Recebemos sua avaliação
        </h1>
       </transition>
       <transition enter-active-class="animated fadeInUp">
        <img
         v-show="formSent"
         style="animation-delay: 500ms"
         src="../assets/images/celebrate.svg"
         alt="Imagem de avaliação concluída"
        />
       </transition>
       <transition enter-active-class="animated fadeIn">
        <p v-show="formSent" style="animation-delay: 250ms">
         Obrigado pela feedback!
        </p>
       </transition>
      </div>
     </div>
    </div>
   </div>
  </section>
 </div>
</template>
<script>
import http from "../http";
import OptionRating from "@/components/OptionRating";

const objProduct = {
 title: "Avaliação do processo de compra",
 firstQuestion:
  "De forma geral, qual a probabilidade de você recomendar as soluções da Celera?",
 secondQuestion:
  "Qual sua satisfação com nosso atendimento, desde o envio do Pedido de Compras até a entrega final?",
 inputLabel:
  "Me diga o que você achou dessa experiência de compra em apenas uma palavra",
};

const objService = {
 title: "Avaliação do processo de serviços",
 firstQuestion:
  "De forma geral, qual a probabilidade de você recomendar as soluções da Celera?",
 secondQuestion:
  "Qual a sua satisfação com nosso atendimento, desde o início do atendimento até a entrega final?",
 inputLabel: "O que te agradou ou pode ser melhorado no processo do serviço",
};

export default {
 components: {
  OptionRating,
 },
 data() {
  return {
   form: {
    type: 0,
    satisfactionSurveyId: "",
    rate1: null,
    rate2: null,
    message: "",
   },
   informations: {},
   warningMessage: "",
   sending: false,
   formSent: false,
  };
 },
 created() {
  this.form.satisfactionSurveyId = this.$route.query.cod;
  this.informations =
   this.$route.query.type == "service" ? objService : objProduct;
 },
 methods: {
  setValueRate1(score) {
   this.form.rate1 = score;
  },
  setValueRate2(score) {
   this.form.rate2 = score;
  },
  send() {
   if (!this.form.rate1 || !this.form.rate2) {
    this.warningMessage = "Por favor, informe as duas notas.";
    return;
   }

   this.form.type = this.$route.query.type == "service" ? 1 : 0;
   this.warningMessage = "";
   this.sending = true;

   http.post("SatisfactionSurvey", this.form).then(() => {
    this.formSent = true;
    this.sending = false;
   });
  },
 },
};
</script>
<style lang="scss" scoped>
section.holder {
 position: relative;
 width: 100%;
 display: grid;
 grid-template-columns: 450px 1fr;
 gap: 0 0;
 @media only screen and (max-width: 1139px) {
  grid-template-columns: 1fr;
 }
}

.intro {
 position: relative;
 min-height: 100vh;
 height: auto;
 @media only screen and (max-width: 1139px) {
  min-height: initial;
  padding: 25px 0;
  img.survey {
   display: none;
  }
  .bg {
   clip-path: initial !important;
  }
  h1 {
   font-size: 1.8em !important;
   margin-top: 25px !important;
  }
 }
 .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 60% 100%, 0% 100%);
  background-color: var(--primary);
 }

 .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 }
 .logo {
  position: relative;
  margin: 0 auto 0 auto;
 }
 h1 {
  font-size: 2em;
  color: #fff;
  text-align: center;
  margin: 85px 45px 0 45px;
 }
 img.survey {
  position: relative;
  max-width: 100%;
  margin-top: 85px;
 }
}

.content .container {
 position: relative;
 min-height: 100vh;
 height: auto;
 display: flex;
 justify-content: center;
 align-items: center;
}

.form {
 position: relative;
 width: 100%;
 max-width: 720px;
 padding: 35px;
 border-radius: 25px;
 background-color: #fff;
 box-shadow: 0 25px 38px 0 rgba(37, 58, 153, 0.144);
 margin: 140px auto 45px auto;
}

img.voting {
 position: relative;
 display: block;
 max-width: 260px;
 margin: -130px auto 20px auto;
}

.rating-item {
 position: relative;
 width: 100%;
 span {
  font-size: 1.2em;
  b {
   font-size: 2.4em;
   color: var(--secondary);
   margin-right: 10px;
  }
 }

 .options-holder {
  position: relative;
  display: flex;
  gap: 1em;
  @media only screen and (max-width: 720px) {
   gap: 6px;
  }
 }
}

.divider {
 position: relative;
 width: 100%;
 height: 1px;
 background-color: #e7ecf3;
 margin: 25px auto;
}

.warning {
 text-align: center;
 margin-top: 25px;
 color: #e42a2a;
 font-size: 1.15em;
}

.sent-holder {
 position: relative;
 width: 100%;
 h1 {
  font-size: 2em;
  color: var(--secondary);
  text-align: center;
 }
 img {
  position: relative;
  display: block;
  margin: 45px auto;
  max-width: 75%;
 }
 p {
  font-size: 1.2em;
  text-align: center;
 }
}
</style>
