import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/css/desktop.css";
import "./assets/css/tablet.css";
import "./assets/css/mobile.css";
import "./assets/css/animate.css";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
