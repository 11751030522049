<template>
  <div class="scale">
    <div class="options-holder">
      <div
        v-for="score in scores"
        :key="score.value"
        @click="setValueRate(score.value)"
        class="options"
        :class="{ active: selectedValue == score.value }"
        :style="`background-color:${score.bgColor}`"
      >
        <span>{{ score.value }}</span>
      </div>
    </div>
    <div class="description-scale">
      <span>{{ label1 }}</span>
      <span>{{ label2 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label1: {
      type: String,
      default: "",
    },
    label2: {
      type: String,
      default: "",
    },
    selectedValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scores: [],
    };
  },
  created() {
    for (let index = 0; index < 11; index++) {
      let score = { value: index };
      if (index < 3) {
        score.bgColor = "#d82f2f";
      }
      if (index == 3) {
        score.bgColor = "#f25b20";
      }
      if (index == 4) {
        score.bgColor = "#f79c27";
      }
      if (index == 5) {
        score.bgColor = "#f4b622";
      }
      if (index == 6) {
        score.bgColor = "#c2d924";
      }
      if (index == 7) {
        score.bgColor = "#a0ec31";
      }
      if (index == 8) {
        score.bgColor = "#6cdf2b";
      }
      if (index > 8) {
        score.bgColor = "#26bb07";
      }

      this.scores.push(score);
    }
  },
  methods: {
    setValueRate(val) {
      this.$emit("setValueRate", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.scale {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 15px;
}

.options-holder {
  position: relative;
  display: flex;
  gap: 1em;
  @media only screen and (max-width: 720px) {
    gap: 6px;
  }
}

.options {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &.active {
    transform: scale(1.4);
    box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.25);
  }
  span {
    color: #fff;
  }
  @media only screen and (min-width: 1140px) {
    &:hover {
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 720px) {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    span {
      font-size: 1.05em;
    }
  }
}

.description-scale {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  span {
    font-size: 1em;
  }
}
</style>
